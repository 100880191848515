//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    href: {
      type: String,
      required: true,
      default: 'https://example.com',
      validator: (string) => string.startsWith('https://') || string.startsWith('mailto:'),
    },
  },
  data: () => ({ prefersReducedMotion: false }),
  computed: {
    linkText() {
      return this.$slots.default[0].text.trim()
    },
  },
  mounted() {
    this.prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches
  },
  methods: {
    /**
     * @param {Object} props Additional gsap timeline props
     */
    timelineFactory(props) {
      return this.$gsap.timeline({
        defaults: { stagger: 0.05, duration: 0.2 },
        ...props,
      })
    },
    showHoverText() {
      if (this.prefersReducedMotion) return

      if (this.prevTl) this.prevTl.kill()

      const tl = this.timelineFactory()

      tl.to(this.$refs.idleChars, { yPercent: -100 })
      tl.to(this.$refs.hoverChars, { yPercent: -100 }, '<')

      this.prevTl = tl
    },
    hideHoverText() {
      if (this.prefersReducedMotion) return

      if (this.prevTl) this.prevTl.kill()

      const tl = this.timelineFactory()

      tl.to(this.$refs.idleChars, { yPercent: 0 })
      tl.to(this.$refs.hoverChars, { yPercent: 0 }, '<')

      this.prevTl = tl
    },
  },
}
